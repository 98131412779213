import React from 'react'
import cn from 'classnames'

import { StepCardProps } from '../../ProfilePage/type'

import styles from './StepCard.module.css'

const StepCard: React.FC<StepCardProps> = ({ card, isHomePage }) => (
  <div className={styles.stepCard}>
    <div className={styles.stepCardHeader}>
      <div className={styles.stepCardIndex}>{card.index}</div>
      <h3 className={styles.stepCardTitle}>{card.title}</h3>
    </div>

    <p
      className={cn(styles.stepCardDescription, {
        [styles.stepCardDescriptionHomePage]: isHomePage,
      })}
    >
      {card.description}
    </p>
  </div>
)

export default StepCard
