import React from 'react'
import cn from 'classnames'
import { currentMarketIdVar } from 'graphql/reactive'
import dayjs from 'lib/dayjs'
import { useTranslation } from 'lib/i18n'
import useCountdown from 'react-use-countdown'

import Link from 'components/Link/Link'
import NoSSR from 'components/NoSSR'

import timerIcon from './timerIcon.svg'

import styles from './CampaignBadge.module.css'

interface CampaignBadgeProps {
  startTime: string
  endTime: string
  countdownTime: string
  marketIds: string[]
  headline: string
  linkPath?: string
  design?: string
}

const CampaignBadge: React.FC<CampaignBadgeProps> = ({
  startTime,
  endTime,
  countdownTime = endTime,
  marketIds,
  headline,
  linkPath = '', // Example: /page/mothers-day
  design,
}) => {
  const { t } = useTranslation()
  const currentMarketId = currentMarketIdVar()

  const utc = dayjs.utc()
  const showCampaignBadge =
    marketIds.includes(currentMarketId) &&
    utc.isAfter(dayjs.utc(startTime)) &&
    utc.isBefore(dayjs.utc(endTime))

  const timeleftMs = useCountdown(
    () => {
      return parseInt(dayjs.utc(countdownTime).format('x'), 10)
    },
    { intervalTime: 60000 },
  )

  if (!showCampaignBadge) return null

  const timeleft = dayjs.duration(timeleftMs, 'milliseconds')

  const days = Math.floor(timeleft.asDays())
  const hours = Math.floor(timeleft.asHours()) - days * 24
  const minutes = timeleft.minutes()

  const Badge = () => (
    <div className={cn(styles.badge, styles[design])}>
      <div className={cn(styles.headline, styles[design])}>
        <div className={styles.headlineText}>{headline}</div>
        {countdownTime && timeleftMs > 0 && (
          <>
            <img src={timerIcon} className={styles.timerIcon} alt="countdown" />
            <NoSSR>
              {days > 0 &&
                t('CampaignBadge.days', {
                  defaultValue: '{{count}} day',
                  defaultValue_plural: '{{count}} days',
                  count: days,
                })}{' '}
              {hours > 0 &&
                t('CampaignBadge.hours', {
                  defaultValue: '{{count}} hour',
                  defaultValue_plural: '{{count}} hours',
                  count: hours,
                })}{' '}
              {t('CampaignBadge.minutes', {
                defaultValue: '{{count}} minute',
                defaultValue_plural: '{{count}} minutes',
                count: minutes,
              })}
            </NoSSR>
          </>
        )}
      </div>
    </div>
  )

  if (!linkPath) return <Badge />

  return (
    <Link href={linkPath}>
      <a>
        <Badge />
      </a>
    </Link>
  )
}

export default CampaignBadge
