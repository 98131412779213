import React from 'react'
import type { Amount, ImageAttachment, PublicProfileProduct } from 'apollo-gql'
import Image from 'next/image'

import Heading from 'components/Heading/Heading'
import Link from 'components/Link/Link'

import styles from './ProfileCardSecondary.module.css'

type ProfilePictureProps = {
  profilePicture: ImageAttachment
  alt?: string
}

const ProfilePicture = ({ profilePicture, alt = '' }: ProfilePictureProps) => {
  if (!profilePicture) {
    return null
  }

  return (
    <Image
      src={profilePicture?.cardUrl}
      alt={alt}
      width={180}
      height={180}
      className={styles.image}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style={{
        background:
          profilePicture && `rgb(${profilePicture.meanBackgroundColorHex})`,
      }}
      unoptimized
    />
  )
}

type PriceProps = {
  amount: Amount
}

const Price = ({ amount }: PriceProps) => (
  <div className={styles.price}>{amount.formattedValue}</div>
)

type ProfileCardSecondaryProps = {
  profileProduct: PublicProfileProduct
  campaignTitle?: string
  campaignColor?: string
}

const ProfileCardSecondary: React.FC<ProfileCardSecondaryProps> = ({
  profileProduct,
  campaignTitle,
  campaignColor,
}) => {
  const { profile } = profileProduct
  const { name, slug, title, profilePicture } = profile

  return (
    <Link href={`/profile/${slug}`}>
      <a className={styles.profileCardSecondary}>
        <aside className={styles.picture}>
          <ProfilePicture profilePicture={profilePicture} alt={name} />
        </aside>
        <main className={styles.content}>
          <div className={styles.priceAndPlayWrapper}>
            {profileProduct && <Price amount={profileProduct.price} />}
          </div>

          <div className={styles.details}>
            {campaignTitle && (
              <p
                className={styles.campaignTitle}
                style={campaignColor ? { color: campaignColor } : {}}
              >
                {campaignTitle}
              </p>
            )}

            <Heading level="h5">
              <h4>{name}</h4>
            </Heading>
            <div className={styles.title}>{title}</div>
          </div>
        </main>
        <footer className={styles.footer}>
          <button className={styles.bookBtn} type="button">
            ›
          </button>
        </footer>
      </a>
    </Link>
  )
}

export default ProfileCardSecondary
