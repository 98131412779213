import React from 'react'
import { logger } from 'lib/logger'
import dynamic from 'next/dynamic'

import Categories from 'components/CategoriesCardSmall/Categories'
import CmsBanner from 'components/CmsBanner/CmsBanner'
import CmsColoredBanner from 'components/CmsColoredBanner/CmsColoredBanner'
import CmsHeroWithImage from 'components/CmsHero/HeroWithImage'
// eslint-disable-next-line import/no-cycle
import CmsItemBasicABTest from 'components/CmsItemBasicABTest/CmsItemBasicABTest'
// eslint-disable-next-line import/no-cycle
import CmsItemMultiVariantTest from 'components/CmsItemMultiVariantTest/CmsItemMultiVariantTest'
import CmsScrollCardsList from 'components/CmsScrollCardsList/CmsScrollCardsList'
import ContentCardList from 'components/ContentCardList/ContentCardList'
import CustomerReviews from 'components/CustomerReviews/CustomerReviews'
import DiscountCoupon from 'components/DiscountCoupon/DiscountCoupon'
import LandingContentSection from 'components/LandingContentSection/LandingContentSection'
import LandingCtaSection from 'components/LandingCtaSection/LandingCtaSection'
import LandingForm from 'components/LandingForm/LandingForm'
import LandingIntro from 'components/LandingIntro/LandingIntro'
import MerchCardList from 'components/MerchCardList/MerchCardList'
import ProfileCollectionCampaign from 'components/ProfileCollectionCampaign/ProfileCollectionCampaign'
import ProfileCollectionCardList from 'components/ProfileCollectionCardList/ProfileCollectionCardList'
import ProfileCollectionSidescroll from 'components/ProfileCollectionSidescroll/ProfileCollectionSidescroll'
import ProfileCollectionTagList from 'components/ProfileCollectionTagList/ProfileCollectionTagList'
import ProfileCollectionRecentlyViewed from 'components/RecentlyViewedProfiles/RecentlyViewedProfiles'
import StartPageHero from 'components/StartPageHero/StartPageHero'
import VideoRequestCardList from 'components/VideoRequestCardList/VideoRequestCardList'
// eslint-disable-next-line import/no-cycle
import WaveWrapperCmsItem from 'components/WaveWrapperCmsItem/WaveWrapperCmsItem'
import useFeatureFlags from 'hooks/useFeatureFlags'

const MarkdownCmsItem = dynamic(() =>
  import('components/MarkdownCmsItem/MarkdownCmsItem'),
)

export default function CmsItemBasic({
  item,
  wrapItems,
  indexPositionFromTop = 0,
}) {
  const { cmsHero } = useFeatureFlags()

  switch (item.type) {
    case 'ABTest':
      return (
        <CmsItemBasicABTest
          experimentId={item.experimentId}
          original={item.original}
          variant={item.variants.filter(Boolean)[0]}
          wrapItems={wrapItems}
        />
      )

    case 'MultiVariantTest':
      return (
        <CmsItemMultiVariantTest
          experimentId={item.experimentId}
          original={item.original}
          variants={item.variants}
          wrapItems={wrapItems}
        />
      )

    case 'Banner':
      return <CmsBanner {...item} />

    case 'ColoredBanner':
      return <CmsColoredBanner {...item} />

    case 'ScrollCardsList':
      return <CmsScrollCardsList {...item} />

    case 'CategoriesContentSmall':
      return <Categories profileCollections={item.profileCollections} />

    case 'ContentCardList':
      return <ContentCardList title={item.title} cards={item.contentCards} />

    case 'CustomerReviews':
      return (
        <CustomerReviews
          title={item.content.title}
          reviews={item.content.reviews}
        />
      )

    case 'DiscountCoupon':
      return <DiscountCoupon {...item} />

    case 'LandingContentSection':
      return (
        <LandingContentSection
          title={item.content.title}
          description={item.content.description}
          video={item.video?.url}
          reviews={item.reviews}
        />
      )

    case 'LandingCtaSection':
      return (
        <LandingCtaSection
          title={item.content.title}
          description={item.content.description}
          image={item.image}
          ctaButton={item.content.ctaButton}
        />
      )

    case 'LandingForm':
      return (
        <LandingForm
          title={item.title}
          subtitle={item.subtitle}
          formId={item.formId}
          type={item.formType}
          backgroundColor={item.backgroundColor}
        />
      )

    case 'LandingIntro':
      return (
        <LandingIntro
          title={item.content.title}
          description={item.content.description}
          imageOne={item.imageOne}
          imageTwo={item.imageTwo}
        />
      )

    case 'Markdown':
      return <MarkdownCmsItem source={item.content.content} />

    case 'MeetingCard':
      return null

    case 'MerchCardList':
      return (
        <MerchCardList
          title={item.content.title}
          cards={item.merchCards}
          fromprice={item.fromPrice}
          wrapItem={wrapItems}
        />
      )

    case 'ProfileCollectionCampaign':
      return (
        <ProfileCollectionCampaign
          linkPath={item.linkPath}
          title={item.content.title}
          color={item.content.color}
          description={item.content.description}
          profileCollectionSlug={item.content.profileCollection}
        />
      )

    case 'ProfileCollectionCardList':
      return (
        <ProfileCollectionCardList
          profileCollectionSlugs={item.content.profileCollectionsList}
        />
      )

    case 'ProfileCollectionContent':
      return (
        <ProfileCollectionSidescroll
          title={item.content.title}
          profileCollection={item.profileCollection}
          linkPath={item.linkPath}
          indexPositionFromTop={indexPositionFromTop}
          prioritizeImages
        />
      )

    case 'ProfileCollectionRecentlyViewed':
      return <ProfileCollectionRecentlyViewed renderLimit={2} />

    case 'ProfileCollectionTagList':
      return (
        <ProfileCollectionTagList
          profileCollectionSlugs={item.content.profileCollectionsList}
          wrapItem={wrapItems}
        />
      )

    case 'VideoRequestCardList':
      return (
        <VideoRequestCardList
          title={item.content.title}
          videoRequests={item.videoRequests.edges.map((edge) => edge.node)}
          extended={false}
          wrapItem={wrapItems}
        />
      )

    case 'WaveWrapper':
      return (
        <WaveWrapperCmsItem
          color={item.color}
          cmsItems={item.cmsItems}
          wrapItem={wrapItems}
          basic
        />
      )
    case 'HeroWithImage':
      return <CmsHeroWithImage {...item} />

    case 'HeroStartPage':
      return cmsHero ? <StartPageHero /> : null

    default:
      logger.warn(`Missing component for ${item.type}`)
      return null
  }
}
