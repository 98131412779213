import React from 'react'
import { StarFilled } from '@ant-design/icons'
import { useMarketContext } from 'contexts/MarketContext'
import dayjs from 'lib/dayjs'

import styles from './CustomerReview.module.css'

function CustomerReview({ review }) {
  const { market } = useMarketContext()
  const { dateFormat } = market.selectedMarket

  const { rating, name, content, date } = review
  return (
    <article className={styles.wrapper}>
      <header className={styles.stars}>
        {[...Array(rating).keys()].map((n) => (
          <StarFilled key={n} className={styles.star} />
        ))}
      </header>
      <main className={styles.text}>{content}</main>

      <footer className={styles.author}>
        {name}
        <br />
        {dayjs(date).format(dateFormat)}
      </footer>
    </article>
  )
}

export default CustomerReview
