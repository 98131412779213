import React from 'react'

import Typography from 'components/UI/Typography/Typography'

const TITLE_WEIGHT_OPTIONS = {
  h1: Typography.H1,
  h2: Typography.H2,
  h3: Typography.H3,
  h4: Typography.H4,
  h5: Typography.H5,
  b: Typography.BodyBold,
} as const

export type TITLE_WEIGHTS = keyof typeof TITLE_WEIGHT_OPTIONS

const useTypographyWeight = (weight: TITLE_WEIGHTS): React.FC =>
  TITLE_WEIGHT_OPTIONS[weight] || TITLE_WEIGHT_OPTIONS.h2

export { useTypographyWeight, useTypographyWeight as default }
