import React from 'react'
import { useTranslation } from 'lib/i18n'

import Heading from 'components/Heading/Heading'

import StepCardSwiper from './StepCardSwiper/StepCardSwiper'

import styles from './AboutMemmo.module.css'

type AboutMemmoProps = {
  isHomePage?: boolean
}

const AboutMemmo = ({ isHomePage = false }: AboutMemmoProps): JSX.Element => {
  const { t } = useTranslation()

  const cards = [
    {
      index: 1,
      title: t('AboutMemmo.card1.title', 'Find a celebrity'),
      description: t(
        'AboutMemmo.card1.description',
        'Thousands of celebrities ready to record the perfect video message.',
      ),
    },
    {
      index: 2,
      title: t('AboutMemmo.card2.title', 'Book them'),
      description: t(
        'AboutMemmo.card2.description',
        'Describe what you want to include in the video.',
      ),
    },
    {
      index: 3,
      title: t('AboutMemmo.card3.title', 'Get your video'),
      description: t(
        'AboutMemmo.card3.description',
        'The talent records your personalised video and sends it your way.',
      ),
    },
    {
      index: 4,
      title: t('AboutMemmo.card4.title', 'Share the joy 🎉'),
      description: t(
        'AboutMemmo.card4.description',
        'Gift the video and watch an unforgettable reaction unfold!',
      ),
    },
  ]

  return (
    <section className={styles.aboutMemmo} data-cy="profilePageAboutMemmo">
      {!isHomePage && (
        <Heading className={styles.aboutMemmoHeader}>
          <h3 className={styles.aboutMemmoHeading}>
            {t('ProfilePage.AboutMemmo.heading1', 'How memmo.me works')}
          </h3>
        </Heading>
      )}

      <StepCardSwiper cards={cards} isHomePage={isHomePage} />
    </section>
  )
}

export default AboutMemmo
