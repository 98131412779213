import React from 'react'
import type { PublicProfileProduct } from 'apollo-gql'
import { useTranslation } from 'lib/i18n'
import { getRecentlyViewed } from 'lib/recentlyViewed'

import Heading from 'components/Heading/Heading'
import NoSSR from 'components/NoSSR'
import ProfileCollectionSkeleton from 'components/ProfileCollectionSkeleton/ProfileCollectionSkeleton'
import ProfileSidescroll from 'components/ProfileSidescroll/ProfileSidescroll'
import Wrapper from 'components/Wrapper/Wrapper'
import usePublicDefaultProfileProducts from 'hooks/usePublicDefaultProfileProducts'

import styles from './RecentlyViewedProfiles.module.css'

type InnerRecentlyViewedProfilesProps = {
  profileSlug: string
  renderLimit?: number
}

type ProfileProps = {
  slug: string
  defaultProfileProduct: PublicProfileProduct
}
type ProfileProductsDictReturnType =
  | {
      [key: string]: PublicProfileProduct
    }
  | Record<string, never>

const InnerRecentlyViewedProfiles: React.FC<
  InnerRecentlyViewedProfilesProps
> = ({ profileSlug, renderLimit = 0 }) => {
  const { t } = useTranslation()
  const profileSlugs = getRecentlyViewed(profileSlug)
  const shouldRender = profileSlugs?.length > renderLimit

  const { data = [], loading } = usePublicDefaultProfileProducts(profileSlugs, {
    fetchPolicy: 'cache-and-network',
  })

  const titleComp = (
    <h2>{t('RecentlyViewedProfiles.title', 'Recently Viewed')}</h2>
  )

  if (profileSlugs?.length && loading && shouldRender) {
    return (
      <div className={styles.loader}>
        <Heading level="h3">{titleComp}</Heading>
        <ProfileCollectionSkeleton amount={profileSlugs?.length} />
      </div>
    )
  }

  const profileProductDictionary =
    data?.reduce(
      (prev: ProfileProductsDictReturnType, current: ProfileProps) => {
        return {
          ...prev,
          [current.slug]: current.defaultProfileProduct,
        }
      },
      {},
    ) || {}

  const profileProducts = profileSlugs
    .map((slug) => profileProductDictionary[slug])
    .filter((x) => x)
    .slice(0, 7)

  if (!profileProducts?.length || !shouldRender) return null

  return (
    <ProfileSidescroll title={titleComp} profileProducts={profileProducts} />
  )
}

type RecentlyViewedProfilesProps = {
  profileSlug: string
}

const RecentlyViewedProfiles: React.FC<RecentlyViewedProfilesProps> = (
  props,
) => {
  return (
    <NoSSR>
      <Wrapper edgeToEdge>
        <InnerRecentlyViewedProfiles {...props} />
      </Wrapper>
    </NoSSR>
  )
}

export default RecentlyViewedProfiles
