import React from 'react'
import cn from 'classnames'

import styles from './Skeleton.module.css'

type SkeletonProps = {
  width?: number
  height?: number
  showLoader?: boolean
}

const Skeleton: React.FC<SkeletonProps> = ({
  width = 160,
  height = 280,
  showLoader = true,
}) => (
  <div
    className={cn(styles.skeleton, { [styles.skeletonLoading]: showLoader })}
    style={{ width, height }}
  >
    {showLoader && <div className={styles.skeletonText} />}
  </div>
)

export default Skeleton
