import React, { useMemo } from 'react'
import type { PublicCmsItemColoredBanner } from 'apollo-gql'

import { TITLE_WEIGHTS, useTypographyWeight } from 'hooks/useTypographyWeight'

import styles from './CmsColoredBannerGridItem.module.css'

const CmsColoredBannerGridItem: React.FC<PublicCmsItemColoredBanner> = ({
  titleWeight = 'h2',
  title,
  titleColor,
  text,
  textColor,
  backgroundColor,
  backgroundImage,
  ctaButtonLink,
}) => {
  const TypographyTitle = useTypographyWeight(titleWeight as TITLE_WEIGHTS)

  const wrapperStyle = useMemo(
    () => ({
      backgroundColor,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundImage: backgroundImage && `url(${backgroundImage.url})`,
    }),
    [backgroundImage, backgroundColor],
  )

  return (
    <a href={ctaButtonLink} className={styles.clickableBanner}>
      <div style={wrapperStyle} className={styles.coloredBanner}>
        <TypographyTitle>
          <span
            style={{
              color: titleColor,
            }}
            className={styles.coloredBannerTitle}
          >
            {title}
          </span>
        </TypographyTitle>
        <TypographyTitle>
          <span
            style={{ color: textColor }}
            className={styles.coloredBannerTitle}
          >
            {text}
          </span>
        </TypographyTitle>
      </div>
    </a>
  )
}

export default CmsColoredBannerGridItem
