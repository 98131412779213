import { gql } from '@apollo/client'

const fullCmsItemPageHeaderFragment = gql`
  fragment fullCmsItemPageHeaderFragment on PublicCmsItemPageHeader {
    id
    type
    content
    video
    title
    tagline
    buttonText
    waveWrapperColor
    businessPage
    videoBackgroundDesktop {
      url(preset: VIDEO_DEFAULT)
    }
    videoBackgroundMobile {
      url(preset: VIDEO_DEFAULT)
    }
    imageBackgroundDesktop {
      url
    }
    imageBackgroundMobile {
      url
    }
  }
`

export default fullCmsItemPageHeaderFragment
