import { gql } from '@apollo/client'

import fullCmsItemFragment from './fullCmsItemFragment'
import fullCmsItemPageHeaderFragment from './fullCmsItemPageHeaderFragment'
import fullCmsItemPageMetadataFragment from './fullCmsItemPageMetadataFragment'

const fullPageFragment = gql`
  fragment fullPageFragment on Page {
    slug
    marketId
    publicCmsItems {
      ...fullCmsItemFragment
      ... on PublicCmsItemWaveWrapper {
        id
        type
        color
        cmsItems {
          ...fullCmsItemFragment
        }
      }
    }

    cmsItemPageHeader {
      ...fullCmsItemPageHeaderFragment
    }

    cmsItemPageMetadata {
      ...fullCmsItemPageMetadataFragment
    }

    cmsItemPageFooter {
      ...fullCmsItemFragment
    }

    title
  }

  ${fullCmsItemFragment}
  ${fullCmsItemPageMetadataFragment}
  ${fullCmsItemPageHeaderFragment}
`

export default fullPageFragment
