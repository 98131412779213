import React from 'react'
import cn from 'classnames'
import Image from 'next/image'

import Heading from 'components/Heading/Heading'
import LandingParagraph from 'components/LandingParagraph/LandingParagraph'
import Wrapper from 'components/Wrapper/Wrapper'

import styles from './LandingIntro.module.css'

function LandingIntro({ title, description, imageOne, imageTwo }) {
  const imageOneMeanBackgroundColor =
    imageOne && `rgb(${imageOne.meanBackgroundColorHex})`

  const imageTwoMeanBackgroundColor =
    imageTwo && `rgb(${imageTwo.meanBackgroundColorHex})`

  return (
    <>
      <Wrapper>
        <div className={styles.landingIntro}>
          <section className={styles.introSection}>
            <Heading level="h1">
              <h1>{title}</h1>
            </Heading>
            <LandingParagraph>{description}</LandingParagraph>
          </section>
          <div className={styles.imagesWrapper}>
            <div
              className={cn(styles.imageWrapper, styles.imageWrapperOne)}
              style={{
                backgroundColor: imageOneMeanBackgroundColor,
              }}
            >
              <Image
                src={imageOne?.url}
                alt=""
                layout="responsive"
                width={imageOne?.width}
                height={imageOne?.height}
                className={styles.image}
              />
            </div>
            <div
              className={cn(styles.imageWrapper, styles.imageWrapperTwo)}
              style={{
                backgroundColor: imageTwoMeanBackgroundColor,
              }}
            >
              <Image
                src={imageTwo?.url}
                alt=""
                layout="responsive"
                width={imageTwo?.width}
                height={imageTwo?.height}
                className={styles.image}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default LandingIntro
