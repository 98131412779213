import React from 'react'
import type { MerchCardFragmentFragment } from 'apollo-gql'
import { formatCurrency } from 'lib/currencies'
import { useTranslation } from 'lib/i18n'

import CtaButton from 'components/CtaButton/CtaButton'
import Heading from 'components/Heading/Heading'
import ImgixImage from 'components/ImgixImage/ImgixImage'
import useMarket from 'hooks/useMarket'

import styles from './MerchCard.module.css'

interface MerchCardProps {
  card: MerchCardFragmentFragment
}

const MerchCard: React.FC<MerchCardProps> = ({ card }) => {
  const { t } = useTranslation()
  const market = useMarket()
  const { title, description, fromPrice, linkUrl, image } = card

  const formattedPrice = formatCurrency({
    currency: market.defaultCurrency,
    valueInCent: fromPrice,
  })

  return (
    <div className={styles.card}>
      <a href={linkUrl} target="_blank" rel="nofollow noreferrer">
        <div className={styles.content}>
          <ImgixImage
            src={image.url}
            alt={description}
            width={400}
            height={400}
            className={styles.image}
          />

          <div className={styles.details}>
            <Heading level="h5">
              <h4 className={styles.title}>{title}</h4>
            </Heading>

            <div className={styles.description}>{description}</div>
          </div>

          <div className={styles.price}>
            {t('MerchCard.fromPrice', 'Fr.')}{' '}
            <span className={styles.formattedPrice}>{formattedPrice}</span>
          </div>

          <CtaButton size="small" block>
            {t('MerchCard.ctaButton', 'Buy in memmostore')}
          </CtaButton>
        </div>
      </a>
    </div>
  )
}

export default MerchCard
