import React from 'react'
import Image from 'next/image'

import ConditionalWrapper from 'components/ConditionalWrapper/ConditionalWrapper'
import Heading from 'components/Heading/Heading'
import Link from 'components/Link/Link'

import styles from './ContentCard.module.css'

function ContentCard({ card }) {
  const {
    title,
    description,
    tagline,
    linkPath,
    linkUrl,
    color,
    backgroundColor,
    backgroundImage,
  } = card

  const imageMeanBackgroundColor =
    backgroundImage && `rgb(${backgroundImage.meanBackgroundColorHex})`

  return (
    <ConditionalWrapper
      condition={linkPath}
      wrapper={(children) => {
        return (
          <Link href={linkPath} passHref>
            {children}
          </Link>
        )
      }}
    >
      <a
        target={linkUrl && '_blank'}
        rel={linkUrl && 'nofollow'}
        href={linkUrl || undefined}
        className={styles.card}
        style={{
          backgroundColor: backgroundColor || imageMeanBackgroundColor,
          color,
        }}
        data-cy="contentCard"
      >
        <div className={styles.aspect} />
        {backgroundImage?.url && (
          <Image
            src={backgroundImage?.url}
            width={backgroundImage?.width}
            height={backgroundImage?.height}
            layout="fill"
            alt={title}
            className={styles.image}
          />
        )}
        {tagline && <div className={styles.tagline}>{tagline}</div>}
        <div className={styles.content}>
          {title && (
            <Heading level="h5" color={color}>
              <h4 className={styles.title}>{title}</h4>
            </Heading>
          )}

          {description && (
            <div className={styles.description}>{description}</div>
          )}
        </div>
      </a>
    </ConditionalWrapper>
  )
}

export default ContentCard
