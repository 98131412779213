import { gql } from '@apollo/client'

const fullCmsItemPageMetadataFragment = gql`
  fragment fullCmsItemPageMetadataFragment on PublicCmsItemPageMetadata {
    metaTitle
    metaDescription
    metaImage {
      url
    }
    metaVideo {
      url(preset: VIDEO_DEFAULT)
    }
  }
`

export default fullCmsItemPageMetadataFragment
