import React from 'react'
import { logger } from 'lib/logger'
import { grey } from 'lib/theme'

import CategoryTagLink from 'components/ProfilePage/Categories/CategoryTagLink'
import usePublicProfileCollectionTag from 'hooks/usePublicProfileCollectionTag'

const ProfileCollectionTag = ({ profileCollection, profileCollectionSlug }) => {
  const { publicCmsItem } = profileCollection
  const color = publicCmsItem?.content?.color || grey
  const title = publicCmsItem?.content?.title || profileCollectionSlug

  return (
    <CategoryTagLink
      {...{ linkPath: publicCmsItem?.linkPath, profileCollectionSlug }}
      categoryTag={{ color, title }}
    />
  )
}

// eslint-disable-next-line import/prefer-default-export
export const ProfileCollectionTagFromSlug = ({ slug }) => {
  const { loading, error, profileCollection } =
    usePublicProfileCollectionTag(slug)

  if (loading || error) {
    if (error)
      logger.error(
        `[ContentLoad Error] Error loading content for PublicProfileCollectionTag: ${slug}`,
        error,
      )
    return null
  }

  return (
    <ProfileCollectionTag
      profileCollectionSlug={slug}
      profileCollection={profileCollection}
    />
  )
}
