import React from 'react'
import { Col, Row } from 'antd'
import { useTranslation } from 'lib/i18n'

import AboutMemmo from 'components/AboutMemmo/AboutMemmo'

import DisplayHeading from './DisplayHeading'
import WaveWrapper from './WaveWrapper'

import styles from './StartPageHero.module.css'

interface HeaderProps {
  wrapperClassName: string
  ctaWrapperClassName: string
}

const Header = ({
  wrapperClassName,
  ctaWrapperClassName,
}: HeaderProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className={wrapperClassName}>
      <WaveWrapper>
        <Row style={{ width: '100%' }}>
          <Col
            xl={{ span: 16, pull: 4, push: 4 }}
            lg={{ span: 20, pull: 2, push: 2 }}
            md={{ span: 20, pull: 2, push: 2 }}
            xs={24}
          >
            <DisplayHeading>
              {t(
                'startPageHero.title',
                'Personal video messages from famous profiles',
              )}
            </DisplayHeading>
          </Col>
        </Row>
      </WaveWrapper>
      <div className={ctaWrapperClassName}>
        <AboutMemmo isHomePage />
      </div>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StartPageHero: React.FC = () => (
  <Header
    wrapperClassName={styles.hero}
    ctaWrapperClassName={styles.cardsCtaWrapper}
  />
)

export default StartPageHero
