import React from 'react'
import { lightGrey } from 'lib/theme'
import { base64 } from 'lib/utils'

import useResponsive from 'hooks/useResponsive'

import styles from './WaveWrapper.module.css'

const WaveWrapper: React.FC = ({ children }) => {
  const { isMobile } = useResponsive()
  const waveColor = lightGrey

  const mobilePathDown = `<path class="st0" d="M375,25V2.2c-22.6,0.9-34.7,5.5-46.8,10c-13.5,5.1-26.9,10-54.7,10c0,0-0.1,0-0.2,0c-27.5,0-41.4-4.9-55.5-9.8
	C203.1,7.3,188.1,2,157,2c-14.2,0-24.7,1.3-33.4,3.1s-15.4,4.5-22.2,7C94.7,14.7,88,17.2,79.5,19c-8.4,1.8-19.5,3.2-33.2,3.2
	c-13.5,0-23.6-1.2-32.1-3C9,18.1,4.4,16.8,0,15.4V25h45.8h1.5H48h223.4h1h1.2H375z" style='stroke: none; fill: ${waveColor};' />`

  const desktopPathDown = `<path class="st0" d="M1409.4,11.1c-23.1,4-46.1,8-94,8c-47,0-70.7-3.9-94.9-7.8c-22.9-3.6-46.3-7.4-90.4-8.1h-26.6
	c-40.1,0.6-61.1,4.3-82.2,7.9c-23,4-45.9,8-93.6,8c-6.4,0-12.3-0.1-17.8-0.2v0.2c-46.6,0-70-3.9-93.9-7.8
	c-22.7-3.6-45.8-7.4-89.4-8.1h-28.1c-40.3,0.6-61.5,4.3-82.6,7.9c-23.1,4-46.1,8-94,8c-47.1,0-70.8-3.9-95-7.8
	c-22.9-3.6-46.3-7.4-90.4-8.1h-13.7c-48.7,0-71.9,4-95.1,8c-23,4-45.9,8-93.5,8c-46.7,0-70.5-3.9-94.6-7.8c-12.1-2-24.3-4-39.6-5.5
	L0,28h134.1l0,0h1306V6.4C1428.6,7.8,1419,9.4,1409.4,11.1z" style='stroke: none; fill: ${waveColor};' />`

  const mobileViewBox = '0 0 375 26'
  const desktopViewBox = '0 0 1440 28'

  const viewBox = isMobile ? mobileViewBox : desktopViewBox
  const path = isMobile ? mobilePathDown : desktopPathDown

  const svg = base64(
    `<svg xmlns='http://www.w3.org/2000/svg' viewBox="${viewBox}" preserveAspectRatio='none'>${path}</svg>`,
  )

  return (
    <div className={styles.wrapper}>
      {children}

      <div
        style={{
          maskImage: `url(data:image/svg+xml;base64,${svg})`,
          WebkitMaskImage: `url(data:image/svg+xml;base64,${svg})`,
          backgroundColor: waveColor,
        }}
        className={styles.wave}
      />
    </div>
  )
}

export default WaveWrapper
