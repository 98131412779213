import React from 'react'
import cn from 'classnames'

import Heading from 'components/Heading/Heading'
import Wrapper from 'components/Wrapper/Wrapper'

import styles from './Sidescroll.module.css'

interface SidescrollProps {
  title?: string
  isDesktopScrollable?: boolean
}

const Sidescroll: React.FC<SidescrollProps> = ({
  title,
  isDesktopScrollable = true,
  children,
}) => {
  return (
    <Wrapper edgeToEdge data-cy="sideScroll">
      <section className={styles.wrapper}>
        {title && (
          <header className={styles.header}>
            <Heading level="h3">
              <h2>{title}</h2>
            </Heading>
          </header>
        )}
        <div
          className={cn(styles.scrollWrapper, {
            [styles.desktopHidden]: !isDesktopScrollable,
          })}
        >
          {children}
        </div>
      </section>
    </Wrapper>
  )
}

export default Sidescroll
