import React from 'react'
import type { PublicCmsItemColoredBanner } from 'apollo-gql'
import cn from 'classnames'

import CmsColoredBannerGridItem from 'components/CmsColoredBanner/CmsColoredBannerGridItem'
import Sidescroll from 'components/Sidescroll/Sidescroll'

import styles from './CmsScrollCardsList.module.css'

type CmsScrollCardsListProps = {
  scrollCards: PublicCmsItemColoredBanner[]
}

const CmsScrollCardsList = ({
  scrollCards = [],
  ...rest
}: CmsScrollCardsListProps): JSX.Element => {
  const bannersLength = scrollCards?.length ?? 0

  if (!Array.isArray(scrollCards) || bannersLength === 0) {
    return null
  }

  const {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    content: { isDesktopScrollable },
  } = rest || {}

  const cardNumberBase = !isDesktopScrollable && bannersLength

  const preselectedItems = isDesktopScrollable
    ? scrollCards
    : scrollCards.slice(0, cardNumberBase)

  return (
    <Sidescroll isDesktopScrollable={isDesktopScrollable}>
      {preselectedItems.filter(Boolean).map((coloredBanner) => (
        <div
          key={coloredBanner.id}
          className={cn(styles.bannerWrapper, {
            [styles.scrollableStyle]: isDesktopScrollable,
          })}
        >
          <CmsColoredBannerGridItem {...coloredBanner} />
        </div>
      ))}
    </Sidescroll>
  )
}

export default CmsScrollCardsList
