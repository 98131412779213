import React from 'react'
import cn from 'classnames'

import ContentCard from 'components/ContentCard/ContentCard'
import ContentCardSmall from 'components/ContentCardSmall/ContentCardSmall'
import Sidescroll from 'components/Sidescroll/Sidescroll'
import VideoRequestCard from 'components/VideoRequestCard/VideoRequestCard'

import styles from './ContentCardList.module.css'

export default function ContentCardList({ title, cards = [] }) {
  if (!Array.isArray(cards) || cards.length === 0) {
    return null
  }

  return (
    <Sidescroll title={title}>
      {cards.filter(Boolean).map((card) => (
        <div
          key={card.id}
          className={cn({
            [styles.cardWrapper]: card.cardType !== 'GENERIC_SMALL',
            [styles.cardWrapperSmall]: card.cardType === 'GENERIC_SMALL',
          })}
        >
          {card.cardType === 'VIDEO_REQUEST' && card.videoRequest && (
            <VideoRequestCard
              request={card.videoRequest}
              showTextContent
              extended={false}
            />
          )}
          {card.cardType === 'GENERIC' && <ContentCard card={card} />}
          {card.cardType === 'GENERIC_SMALL' && (
            <ContentCardSmall card={card} />
          )}
        </div>
      ))}
    </Sidescroll>
  )
}
