import React from 'react'
import type { PublicProfileCollection } from 'apollo-gql'
import { useTranslation } from 'lib/i18n'

import Sidescroll from 'components/Sidescroll/Sidescroll'

import CategoryCard from './CategoryCard'

import styles from './Categories.module.css'

type CategoriesProps = {
  profileCollections: PublicProfileCollection[]
}

const Categories: React.FC<CategoriesProps> = ({ profileCollections }) => {
  const { t } = useTranslation()
  if (!profileCollections?.length) return null

  const cards = profileCollections.map((item) => ({
    title: item?.publicCmsItem?.content?.title,
    linkPath: item?.publicCmsItem?.content?.linkPath,
    slug: item?.slug,
    color: item?.publicCmsItem?.content?.color,
    id: item?.id,
  }))

  return (
    <Sidescroll title={t('VideoRequestPage.categoriesTitle', 'Categories')}>
      {cards?.filter(Boolean).map((card) => (
        <div key={card.id} className={styles.cardWrapperSmall}>
          <CategoryCard card={card} />
        </div>
      ))}
    </Sidescroll>
  )
}

export default Categories
