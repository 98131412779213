import React from 'react'
import type { MerchCardFragmentFragment } from 'apollo-gql'

import MerchCard from 'components/MerchCard/MerchCard'
import Sidescroll from 'components/Sidescroll/Sidescroll'

import styles from './MerchCardList.module.css'

interface MerchCardListProps {
  title: string
  cards: MerchCardFragmentFragment[]
}

const MerchCardList: React.FC<MerchCardListProps> = ({ title, cards = [] }) => {
  if (!Array.isArray(cards) || cards.length === 0) {
    return null
  }

  return (
    <Sidescroll title={title}>
      {cards.filter(Boolean).map((card) => (
        <div key={card.id} className={styles.cardWrapper}>
          <MerchCard card={card} />
        </div>
      ))}
    </Sidescroll>
  )
}

export default MerchCardList
