import React from 'react'
import type { PublicCmsItemColoredBanner } from 'apollo-gql'

import ColoredBanner from 'components/ColoredBanner/ColoredBanner'

const CmsColoredBanner: React.FC<PublicCmsItemColoredBanner> = ({
  titleWeight = 'h2',
  titleSize = 'h2',
  title,
  titleColor,
  backgroundColor,
  backgroundImage,
  text,
  textColor,
  images,
  ctaButtonLabel,
  ctaButtonLabelColor,
  ctaButtonBorderColor,
  ctaButtonHoverLabelColor = '#000',
  ctaButtonHoverBackgroundColor = '#fff',
  ctaButtonHoverBorderColor = '#000',
  ctaButtonLink,
}) => (
  <ColoredBanner
    title={{
      text: title,
      color: titleColor,
      size: titleSize,
      weight: titleWeight,
    }}
    subtitle={{
      text,
      color: textColor,
    }}
    background={{
      color: backgroundColor,
      image: {
        id: backgroundImage?.id,
        url: backgroundImage?.url,
      },
    }}
    ctaButton={{
      label: {
        text: ctaButtonLabel,
        color: ctaButtonLabelColor,
      },
      link: ctaButtonLink,
      borderColor: ctaButtonBorderColor,
      hoverStyle: {
        borderColor: ctaButtonHoverBorderColor,
        backgroundColor: ctaButtonHoverBackgroundColor,
        labelColor: ctaButtonHoverLabelColor,
      },
    }}
    images={images?.map(({ id, url }) => ({ id, url })) || []}
  />
)

export default CmsColoredBanner
