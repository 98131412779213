import React from 'react'

// eslint-disable-next-line import/no-cycle
import CmsItemBasic from 'components/CmsItem/CmsItemBasic'

/**
 * Removed Amplitude implementation as the AB-test provider,
 * using control as default.
 * TODO Re-implement with an abstraction + a dummy implementation.
 */
export default function CmsItemMultiVariantTest({
  experimentId,
  original,
  variants,
  wrapItems,
}) {
  if (!original || !experimentId) return null

  const originalComponent = (
    <CmsItemBasic key={original.id} item={original} wrapItems={wrapItems} />
  )

  // eslint-disable-next-line no-unused-vars
  const groupedVariants = variants.reduce((acc, next) => {
    const { variantId, cmsItem } = next
    return {
      ...acc,
      [variantId]: (
        <CmsItemBasic key={cmsItem.id} item={cmsItem} wrapItems={wrapItems} />
      ),
    }
  }, {})

  return originalComponent
}
