import React from 'react'

import { DesktopH2, H5 } from 'components/UI/Typography/Typography.new'

import styles from './Header.module.css'

type HeaderProps = {
  title: string
  subtitle?: string
}

const Header = ({ title, subtitle }: HeaderProps): JSX.Element => (
  <div className={styles.header}>
    <DesktopH2 element="h1">{title}</DesktopH2>

    {subtitle && (
      <div className={styles.subtitleContainer}>
        <H5>{subtitle}</H5>
      </div>
    )}
  </div>
)

export default Header
