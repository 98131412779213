import { useContext } from 'react'
import FeatureFlagsContext, {
  FeatureFlagValue,
} from 'contexts/FeatureFlagsContext'

const useFeatureFlags = (): FeatureFlagValue => {
  const context = useContext(FeatureFlagsContext)

  return context
}

export default useFeatureFlags
