import React from 'react'
import dynamic from 'next/dynamic'

import WaveWrapper from 'components/WaveWrapper/WaveWrapper'

// eslint-disable-next-line import/no-cycle
const CmsItem = dynamic(() => import('components/CmsItem/CmsItem'))

// eslint-disable-next-line import/no-cycle
const CmsItemBasic = dynamic(() => import('components/CmsItem/CmsItemBasic'))

export default function WaveWrapperCmsItem({
  color,
  cmsItems,
  wrapItem,
  basic,
}) {
  return (
    <div style={{ marginBottom: 40 }}>
      <WaveWrapper waveColor={color} top bottom inverted>
        <div
          style={{
            backgroundColor: color,
            position: 'relative',
          }}
        >
          {cmsItems.map((cmsItem) =>
            basic ? (
              <CmsItemBasic
                key={cmsItem.id}
                item={cmsItem}
                wrapItems={wrapItem}
              />
            ) : (
              <CmsItem key={cmsItem.id} item={cmsItem} wrapItems={wrapItem} />
            ),
          )}
        </div>
      </WaveWrapper>
    </div>
  )
}
