import React from 'react'
import { white } from 'lib/theme'

import Heading from 'components/Heading/Heading'
import ProfileCollectionLink from 'components/ProfileCollectionLink/ProfileCollectionLink'

import styles from './CategoryCard.module.css'

export interface ContentCardSmallCardProps {
  title: string
  linkPath: string
  color?: string
  slug?: string
}

type ContentCardSmallProps = {
  card: ContentCardSmallCardProps
}

const CategoryCard: React.FC<ContentCardSmallProps> = ({ card }) => {
  const { title, linkPath, color = '#BCCC9A', slug } = card
  return (
    <ProfileCollectionLink linkPath={linkPath} profileCollectionSlug={slug}>
      <a className={styles.card}>
        <div className={styles.contentOverlay} />
        <div
          style={{ backgroundColor: color }}
          className={styles.backgroundColorContainer}
        />
        <div className={styles.content}>
          {title && (
            <Heading level="h5" color={white}>
              <h4 className={styles.title}>{title}</h4>
            </Heading>
          )}
        </div>
      </a>
    </ProfileCollectionLink>
  )
}

export default CategoryCard
