import React from 'react'

import { ProfileCollectionCardFromSlug } from 'components/ProfileCollectionCard/ProfileCollectionCard'

import styles from './ProfileCollectionCardList.module.css'

export default function ProfileCollectionCardList({ profileCollectionSlugs }) {
  if (!profileCollectionSlugs || profileCollectionSlugs.length === 0) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      {profileCollectionSlugs.map((slug) => (
        <ProfileCollectionCardFromSlug key={slug} slug={slug} />
      ))}
    </div>
  )
}
