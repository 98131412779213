import React from 'react'
import type { PublicProfileCollection } from 'apollo-gql'
import { logger } from 'lib/logger'
import { grey } from 'lib/theme'

import ProfileCollectionLink from 'components/ProfileCollectionLink/ProfileCollectionLink'
import usePublicProfileCollection from 'hooks/usePublicProfileCollection'

import styles from './ProfileCollectionCard.module.css'

type ProfileCollectionCardProps = {
  profileCollection: PublicProfileCollection
  profileCollectionSlug: string
}

type ProfileCollectionCardFromSlugProps = {
  slug: string
}

const ProfileCollectionCard: React.FC<ProfileCollectionCardProps> = ({
  profileCollection,
  profileCollectionSlug,
}) => {
  const { publicCmsItem } = profileCollection

  const backgroundColor = publicCmsItem?.content?.color || grey
  const title = publicCmsItem?.content?.title || profileCollectionSlug

  return (
    <ProfileCollectionLink
      linkPath={publicCmsItem.content?.linkPath}
      profileCollectionSlug={profileCollectionSlug}
      scroll={false}
    >
      <a className={styles.card} style={{ backgroundColor }}>
        {title}
      </a>
    </ProfileCollectionLink>
  )
}

// eslint-disable-next-line import/prefer-default-export
export const ProfileCollectionCardFromSlug: React.FC<
  ProfileCollectionCardFromSlugProps
> = ({ slug }) => {
  const { loading, error, profileCollection } = usePublicProfileCollection(slug)

  if (loading || error) {
    if (error)
      logger.error(
        `[ContentLoad Error] Error loading content for PublicProfileCollection: ${slug}`,
        error,
      )
    return null
  }

  return (
    <ProfileCollectionCard
      profileCollectionSlug={slug}
      profileCollection={profileCollection}
    />
  )
}
