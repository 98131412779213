import { gql, QueryResult, useQuery } from '@apollo/client'
import type {
  GetPublicProfilesQuery,
  GetPublicProfilesQueryVariables,
} from 'apollo-gql'
import cardProfileProductFragment from 'graphql/fragments/cardProfileProductFragment'
import { currentMarketIdVar } from 'graphql/reactive'

export const PROFILE_FRAGMENT = gql`
  fragment partialPublicProfile on PublicProfile {
    slug

    defaultProfileProduct {
      ...cardProfileProductFragment
    }
  }

  ${cardProfileProductFragment}
`

export const GET_PUBLIC_PROFILES = gql`
  query GetPublicProfiles($marketId: ID!, $slugs: [String!]) {
    market(id: $marketId) {
      id
      profilesBySlugs(slugs: $slugs) {
        ...partialPublicProfile
      }
    }
  }
  ${PROFILE_FRAGMENT}
`
type UsePublicDefaultProfileProductsReturn = {
  data: GetPublicProfilesQuery['market']['profilesBySlugs']
} & Pick<
  QueryResult<GetPublicProfilesQuery, GetPublicProfilesQueryVariables>,
  'loading' | 'error' | 'networkStatus'
>

export default function usePublicDefaultProfileProducts(
  slugs = [''],
  useQueryParams = {},
): UsePublicDefaultProfileProductsReturn {
  const marketId = currentMarketIdVar()
  const { loading, data, networkStatus, error } = useQuery<
    GetPublicProfilesQuery,
    GetPublicProfilesQueryVariables
  >(GET_PUBLIC_PROFILES, {
    variables: { slugs, marketId },
    ...useQueryParams,
  })

  return {
    data: data?.market?.profilesBySlugs,
    loading,
    error,
    networkStatus,
  }
}
