// eslint-disable-next-line filenames/match-exported
import React from 'react'
import cn from 'classnames'

import styles from './Typography.new.module.css'

type TypographyProps = {
  block?: boolean // render as a block element
  strict?: boolean // render ignoring css media quries
  element?: keyof HTMLElementTagNameMap // render as specific element
  theme?: 'primary' | 'secondary' | 'tertiary' // text color
}

const Typography = (
  displayName: string,
  classes: string[],
): React.FC<TypographyProps> => {
  const Component: React.FC<TypographyProps> = ({
    element,
    block,
    strict,
    theme,
    children,
  }) => {
    if (element) {
      return React.createElement(
        element,
        {
          className: cn(...classes, styles[theme], { [styles.strict]: strict }),
        },
        children,
      )
    }

    if (block) {
      return (
        <div
          className={cn(...classes, styles[theme], { [styles.strict]: strict })}
        >
          {children}
        </div>
      )
    }

    return (
      <span
        className={cn(...classes, styles[theme], { [styles.strict]: strict })}
      >
        {children}
      </span>
    )
  }

  Component.displayName = `Typography.${displayName}`
  return Component
}

export const DisplayHeadingLarge = Typography('DisplayHeadingLarge', [
  styles.displayHeading,
  styles.displayHeadingLarge,
])
export const DisplayHeadingMedium = Typography('DisplayHeadingMedium', [
  styles.displayHeading,
  styles.displayHeadingMedium,
])
export const DisplayHeadingSmall = Typography('DisplayHeadingSmall', [
  styles.displayHeading,
  styles.displayHeadingSmall,
])

export const DesktopH1 = Typography('DesktopH1', [
  styles.heading,
  styles.desktopH1,
])

export const DesktopH2 = Typography('DesktopH1', [
  styles.heading,
  styles.desktopH2,
])

export const H1 = Typography('H1', [styles.heading, styles.h1])
export const H2 = Typography('H2', [styles.heading, styles.h2])
export const H3 = Typography('H3', [styles.heading, styles.h3])
export const H4 = Typography('H4', [styles.heading, styles.h4])
export const H5 = Typography('H5', [styles.heading, styles.h5])
export const H6 = Typography('H6', [styles.heading, styles.h6])

export const BodyText = Typography('BodyText', [styles.body, styles.bodyText])
export const BodyTextBold = Typography('BodyTextBold', [
  styles.body,
  styles.bodyTextBold,
])
export const BodySmall = Typography('BodySmall', [
  styles.body,
  styles.bodySmall,
])
export const BodySmallBold = Typography('BodySmallBold', [
  styles.body,
  styles.bodySmallBold,
])

export const CTA = Typography('CTA', [styles.CTA])

export const MetaText = Typography('MetaText', [styles.meta, styles.metaText])
export const MetaTextBold = Typography('MetaTextBold', [
  styles.meta,
  styles.metaTextBold,
])

export const LinkTextBold = Typography('LinkTextBold', [
  styles.link,
  styles.linkTextBold,
])
export const LinkTextSmallBold = Typography('LinkTextSmallBold', [
  styles.link,
  styles.linkTextSmallBold,
])

const classesPerElement = {
  h1: [styles.heading, styles.h1],
  h2: [styles.heading, styles.h2],
  h3: [styles.heading, styles.h3],
  h4: [styles.heading, styles.h4],
  h5: [styles.heading, styles.h5],
  b: [styles.body, styles.bodyTextBold],
}
export type SupportedHeadingTypes = keyof typeof classesPerElement
type SeoHeadingProps = {
  element: SupportedHeadingTypes
  styleAsTag?: SupportedHeadingTypes
  children: string | React.ReactNode | (string | React.ReactNode)[]
}
export const SeoHeading = ({
  element,
  styleAsTag,
  children,
}: SeoHeadingProps) => {
  const displayName = [element, styleAsTag].filter(Boolean).join('-')
  const Component = Typography(
    displayName,
    classesPerElement[styleAsTag || element],
  )

  return <Component element={element}>{children}</Component>
}
