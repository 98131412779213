import React, { useCallback, useMemo, useState } from 'react'
import cn from 'classnames'
import Image from 'next/image'

import CtaButton from 'components/CtaButton/CtaButton'
import Link from 'components/Link/Link'
import Wrapper from 'components/Wrapper/Wrapper'
import { TITLE_WEIGHTS, useTypographyWeight } from 'hooks/useTypographyWeight'

import styles from './ColoredBanner.module.css'

type Image = {
  id: string
  url: string
}

type ColoredBannerProps = {
  title: {
    text: string
    size?: string
    color?: string
    weight?: string
  }
  subtitle: {
    text: string
    color?: string
  }
  background: {
    color: string
    image: Image
  }
  ctaButton: {
    label: {
      text: string
      color?: string
    }
    link: string
    borderColor: string
    hoverStyle: {
      labelColor: string
      backgroundColor: string
      borderColor: string
    }
  }
  images?: Image[]
}

const ColoredBanner = ({
  title,
  subtitle,
  background,
  ctaButton,
  images,
}: ColoredBannerProps): JSX.Element => {
  const [hoverState, setHoverState] = useState(false)
  const hoverCtaStyles = useMemo(() => {
    const hoverStyle = {
      color: ctaButton.hoverStyle?.labelColor,
      backgroundColor: ctaButton.hoverStyle?.backgroundColor,
      borderColor: ctaButton.hoverStyle?.borderColor,
    }
    const defaultStyle = {
      color: ctaButton.label.color,
      backgroundColor: background.color,
      borderColor: ctaButton.borderColor,
    }

    return hoverState ? hoverStyle : defaultStyle
  }, [
    ctaButton.hoverStyle?.labelColor,
    ctaButton.hoverStyle?.backgroundColor,
    ctaButton.hoverStyle?.borderColor,
    ctaButton.borderColor,
    background.color,
    ctaButton.label.color,
    hoverState,
  ])

  const onSetHover = useCallback(
    (isHovered) => setHoverState(isHovered),
    [setHoverState],
  )

  const TypographyTitle = useTypographyWeight(title.weight as TITLE_WEIGHTS)

  const wrapperStyle = useMemo(
    () => ({
      backgroundColor: background.color,
      backgroundImage: background.image?.url && `url(${background.image.url})`,
    }),
    [background.color, background.image],
  )

  const titleStyle = {
    color: title.color,
  }

  const textStyle = {
    color: subtitle.color,
  }
  const onHover = () => onSetHover(true)
  const onUnHover = () => onSetHover(false)

  const shouldBannerBeClickable = !!ctaButton.link

  const coloredBanner = (
    <div style={wrapperStyle} className={styles.coloredBanner}>
      <div>
        <TypographyTitle>
          <span
            style={titleStyle}
            className={cn(styles.coloredBannerTitle, styles[title.size])}
          >
            {title.text}
          </span>
        </TypographyTitle>
        <p style={textStyle} className={styles.coloredBannerText}>
          {subtitle.text}
        </p>
        {images?.length > 0 && (
          <div className={styles.coloredBannerImages}>
            {images.map((image) => (
              <div key={image.id} className={styles.coloredBannerImage}>
                <Image
                  alt={title.text}
                  src={image.url}
                  layout="responsive"
                  objectFit="contain"
                  width={68}
                  height={48}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      {ctaButton.label.text && ctaButton.link && (
        <div className={styles.coloredBannerCtaWrapper}>
          <CtaButton
            href={ctaButton.link}
            style={hoverCtaStyles}
            onMouseOver={onHover}
            onMouseOut={onUnHover}
            className={styles.coloredBannerCta}
            size="large"
          >
            {ctaButton.label.text}
          </CtaButton>
        </div>
      )}
    </div>
  )

  return (
    <Wrapper>
      {shouldBannerBeClickable ? (
        <div
          className={styles.clickableBanner}
          onMouseOver={onHover}
          onMouseOut={onUnHover}
          onFocus={onHover}
          onBlur={onUnHover}
        >
          <Link href={ctaButton.link}>{coloredBanner}</Link>
        </div>
      ) : (
        coloredBanner
      )}
    </Wrapper>
  )
}

export default ColoredBanner
