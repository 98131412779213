import React from 'react'
import { Col, Row } from 'antd'
import dynamic from 'next/dynamic'

import Wrapper from 'components/Wrapper/Wrapper'

import Header from './Header'

import styles from './LandingForm.module.css'

const CustomForm = dynamic(() => import('components/CustomForm/CustomForm'))
const EnrollForm = dynamic(() => import('components/EnrollForm/EnrollForm'))

type LandingFormProps = {
  title: string
  subtitle?: string
  formId: string
  type: string
  backgroundColor?: string
}

const LandingForm = ({
  title,
  subtitle,
  formId,
  type,
  backgroundColor,
}: LandingFormProps): JSX.Element => {
  const formType = type || 'ENROLL'

  return (
    <div id={formId}>
      <Wrapper>
        <Row>
          <Col xs={24} md={{ span: 20, offset: 2 }}>
            <section className={styles.section} style={{ backgroundColor }}>
              <div className={styles.container}>
                {formType !== 'ENROLL' && (
                  <Header title={title} subtitle={subtitle} />
                )}

                {formType === 'ENROLL' ? (
                  <EnrollForm title={title} />
                ) : (
                  <CustomForm formId={formId} />
                )}
              </div>
            </section>
          </Col>
        </Row>
      </Wrapper>
    </div>
  )
}

export default LandingForm
