import React from 'react'
import { isServer } from 'lib/utils'

// eslint-disable-next-line import/no-cycle
import CmsItemBasic from 'components/CmsItem/CmsItemBasic'

/**
 * Removed Amplitude implementation as the AB-test provider,
 * using control as default.
 * TODO Re-implement with an abstraction + a dummy implementation.
 */
export default function CmsItemBasicABTest({
  experimentId,
  original,
  // eslint-disable-next-line no-unused-vars
  variant,
  wrapItems,
}) {
  if (!original || !experimentId) return null

  const originalComponent = (
    <CmsItemBasic key={original.id} item={original} wrapItems={wrapItems} />
  )

  if (isServer()) {
    return originalComponent
  }

  return originalComponent
}
