import React from 'react'

import Skeleton from './Skeleton'

import styles from './ProfileCollectionSkeleton.module.css'

type ProfileCollectionSkeletonProps = {
  amount?: number
  width?: number
  height?: number
  marginRight?: number
  showLoader?: boolean
}

const ProfileCollectionSkeleton: React.FC<ProfileCollectionSkeletonProps> = ({
  amount = 1,
  width,
  height,
  marginRight = 10,
  showLoader = true,
}) => {
  let checkedAmount = amount
  if (checkedAmount > 7) checkedAmount = 7

  return (
    <div className={styles.skeletonWrapper}>
      {[...Array(amount)].map((_, key) => (
        <div
          key={`skeleton-${Math.random()}`}
          className={styles.skeletonItem}
          style={key !== amount - 1 ? { marginRight } : undefined}
        >
          <Skeleton width={width} height={height} showLoader={showLoader} />
        </div>
      ))}
    </div>
  )
}

export default ProfileCollectionSkeleton
