import React, { useCallback, useMemo, useState } from 'react'
import type { PublicCmsItemHeroWithImage } from 'apollo-gql'
import Image from 'next/image'

import CtaButton from 'components/CtaButton/CtaButton'
import Link from 'components/Link/Link'
import {
  SeoHeading,
  SupportedHeadingTypes,
} from 'components/UI/Typography/Typography.new'
import Wrapper from 'components/Wrapper/Wrapper'

import styles from './HeroWithImage.module.css'

const HeroWithImage: React.FC<PublicCmsItemHeroWithImage> = ({
  titleWeight = 'h2',
  titleSize = 'h2',
  title,
  titleColor,
  textWeight,
  textSize,
  text,
  textColor,
  imageMobile,
  imageDesktop,
  ctaButtonLabel,
  ctaButtonLabelColor,
  ctaButtonBorderColor,
  ctaButtonHoverLabelColor = '#000',
  ctaButtonHoverBackgroundColor = '#fff',
  ctaButtonHoverBorderColor = '#000',
  ctaButtonLink,
  boxColor,
}) => {
  const [hoverState, setHoverState] = useState(false)

  const innerBoxStyles = useMemo(() => {
    return { '--boxColor': boxColor } as React.CSSProperties
  }, [boxColor])

  const hoverCtaStyles = useMemo(() => {
    const hoverStyle = {
      color: ctaButtonHoverLabelColor,
      backgroundColor: ctaButtonHoverBackgroundColor,
      borderColor: ctaButtonHoverBorderColor,
    }
    const defaultStyle = {
      color: ctaButtonLabelColor,
      backgroundColor: 'var(--secondary-dark)',
      borderColor: ctaButtonBorderColor,
    }

    return hoverState ? hoverStyle : defaultStyle
  }, [
    ctaButtonHoverLabelColor,
    ctaButtonHoverBackgroundColor,
    ctaButtonHoverBorderColor,
    ctaButtonBorderColor,
    ctaButtonLabelColor,
    hoverState,
  ])

  const onSetHover = useCallback(
    (isHovered) => setHoverState(isHovered),
    [setHoverState],
  )

  const onHover = () => onSetHover(true)
  const onUnHover = () => onSetHover(false)

  return (
    <Wrapper edgeToEdge>
      <Link href={ctaButtonLink}>
        <div className={styles.container}>
          <div className={styles.innerBox} style={innerBoxStyles}>
            <div className={styles.contentContainer}>
              <span>
                <SeoHeading
                  element={titleWeight as SupportedHeadingTypes}
                  styleAsTag={titleSize as SupportedHeadingTypes}
                >
                  <span style={{ color: titleColor }}>{title}</span>
                </SeoHeading>
              </span>
              <span className={styles.text}>
                <SeoHeading
                  element={textWeight as SupportedHeadingTypes}
                  styleAsTag={textSize as SupportedHeadingTypes}
                >
                  <span style={{ color: textColor }}>{text}</span>
                </SeoHeading>
              </span>
              {ctaButtonLabel && ctaButtonLink && (
                <div className={styles.cta}>
                  <CtaButton
                    style={hoverCtaStyles}
                    onMouseOver={onHover}
                    onMouseOut={onUnHover}
                    size="large"
                  >
                    {ctaButtonLabel}
                  </CtaButton>
                </div>
              )}
            </div>

            <div className={styles.imageContainer}>
              <div className={styles.mobile}>
                <Image
                  src={imageMobile.url}
                  height={imageMobile.height}
                  width={imageMobile.width}
                  priority
                />
              </div>
              <div className={styles.desktop}>
                <Image
                  src={imageDesktop.url}
                  height={imageDesktop.height}
                  width={imageDesktop.width}
                />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </Wrapper>
  )
}

export default HeroWithImage
