import React from 'react'
import { useTranslation } from 'lib/i18n'
import { logger } from 'lib/logger'

import CtaLink from 'components/CtaLink/CtaLink'
import Heading from 'components/Heading/Heading'
import ProfileCardSecondary from 'components/ProfileCardSecondary/ProfileCardSecondary'
import ProfileCollectionLink from 'components/ProfileCollectionLink/ProfileCollectionLink'
import usePublicProfileCollection from 'hooks/usePublicProfileCollection'

import styles from './ProfileCollectionCampaign.module.css'

type ProfileCollectionCampaignProps = {
  title: string
  description: string
  color: string
  profileCollectionSlug: string
  linkPath: string
}

const ProfileCollectionCampaign: React.FC<ProfileCollectionCampaignProps> = ({
  title,
  description,
  color,
  profileCollectionSlug,
  linkPath,
}) => {
  const { t } = useTranslation()
  const { profileCollection, loading, error } = usePublicProfileCollection(
    profileCollectionSlug,
    { first: 4 },
  )

  if (error) {
    logger.error(
      `[ContentLoad Error] Error loading content for PublicProfileCollection: ${profileCollectionSlug}`,
      error,
    )
    return null
  }

  const profileProducts = profileCollection?.profileProducts?.edges || []
  const totalCount = profileCollection?.profileProducts.totalCount || 0

  const remainingCount = totalCount - profileProducts.length

  return (
    <article className={styles.wrapper} style={{ backgroundColor: color }}>
      <header className={styles.header}>
        <ProfileCollectionLink
          linkPath={linkPath}
          profileCollectionSlug={profileCollectionSlug}
        >
          <a>
            <Heading level="h3" color="light">
              <h2 className={styles.title}>{title}</h2>
            </Heading>
          </a>
        </ProfileCollectionLink>
        {description && <p className={styles.description}>{description}</p>}
      </header>

      {loading && <div>Loading...</div>}

      <main>
        {profileProducts
          .slice(0, 4)
          .map((edge) => edge.node)
          .map((profileProduct) => (
            <div key={profileProduct.id} className={styles.profileWrapper}>
              <ProfileCardSecondary
                campaignTitle={title}
                campaignColor={color}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                profileProduct={profileProduct}
              />
            </div>
          ))}
      </main>

      {remainingCount > 0 && (
        <footer className={styles.footer}>
          <ProfileCollectionLink
            linkPath={linkPath}
            profileCollectionSlug={profileCollectionSlug}
          >
            <CtaLink lightColor>
              {t('ProfileCollectionCampaign.showAll', 'Show all')}
            </CtaLink>
          </ProfileCollectionLink>
        </footer>
      )}
    </article>
  )
}

export default ProfileCollectionCampaign
