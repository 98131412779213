import React from 'react'
import { Col, Row } from 'antd'
import Image from 'next/image'

import CtaButton from 'components/CtaButton/CtaButton'
import Heading from 'components/Heading/Heading'
import LandingParagraph from 'components/LandingParagraph/LandingParagraph'
import Wrapper from 'components/Wrapper/Wrapper'

import styles from './LandingCtaSection.module.css'

export default function LandingCtaSection({
  title,
  description,
  image,
  ctaButton,
}) {
  return (
    <Wrapper>
      <section className={styles.landingCtaSection}>
        <Row align="middle">
          {image?.url && (
            <Col xs={{ span: 18, offset: 3 }} md={{ span: 7, offset: 0 }}>
              <Image
                src={image?.url}
                alt=""
                width={image.width}
                height={image.height}
                className={styles.image}
                layout="responsive"
              />
            </Col>
          )}
          <Col xs={24} md={{ span: 14, offset: 2 }}>
            <Heading level="h1">
              <h2>{title}</h2>
            </Heading>
            <LandingParagraph>{description}</LandingParagraph>
            <CtaButton className={styles.ctaButton}>
              <a href={ctaButton.href}>{ctaButton.text}</a>
            </CtaButton>
          </Col>
        </Row>
      </section>
    </Wrapper>
  )
}
