import React from 'react'
import { RightOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { grey } from 'lib/theme'

import ProfileCollectionLink from 'components/ProfileCollectionLink/ProfileCollectionLink'

import { CategoryTagProps } from '../type'

import styles from './CategoryTagLink.module.css'

type CategoryTagLinkProps = {
  linkPath: string
  profileCollectionSlug: string
  categoryTag: CategoryTagProps
  size?: 'large' | 'small'
}

const CategoryTagLink: React.FC<CategoryTagLinkProps> = ({
  linkPath,
  profileCollectionSlug,
  categoryTag,
  size = 'large',
}) => {
  const { color = grey, title } = categoryTag
  return (
    <ProfileCollectionLink
      linkPath={linkPath}
      profileCollectionSlug={profileCollectionSlug}
      passHref
    >
      <a
        className={cn(styles.categoryTag, styles[`categoryTag--${size}`])}
        style={{ backgroundColor: color }}
      >
        <span className={styles.categoryTagTitle}>{title}</span>
        <RightOutlined className={styles.categoryTagIcon} />
      </a>
    </ProfileCollectionLink>
  )
}

export default CategoryTagLink
