import React from 'react'
import { Col, Row } from 'antd'

import CustomerReview from 'components/CustomerReviews/CustomerReview'
import Heading from 'components/Heading/Heading'
import LandingParagraph from 'components/LandingParagraph/LandingParagraph'
import PhoneVideo from 'components/PhoneVideo/PhoneVideo'
import Wrapper from 'components/Wrapper/Wrapper'

import styles from './LandingContentSection.module.css'

function LandingContentSection({ title, description, video, reviews }) {
  return (
    <Wrapper>
      <section className={styles.landingContentSection}>
        <Row align="middle" style={{ marginTop: 20 }}>
          <Col
            xs={{ span: 20, offset: 2 }}
            md={{ span: 8, offset: 0 }}
            className={styles.phoneVideo}
          >
            <PhoneVideo videoSrc={video} />
          </Col>
          <Col xs={24} md={{ span: 14, offset: 2 }}>
            <Row>
              <Col span={24}>
                <Heading level="h1">
                  <h2>{title}</h2>
                </Heading>
                <LandingParagraph
                  style={{
                    margin: '20px 0 20px',
                  }}
                >
                  {description}
                </LandingParagraph>
              </Col>
              {reviews.map((review) => (
                <Col span={8} key={`review_${review.id}`}>
                  <CustomerReview review={review} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </section>
    </Wrapper>
  )
}

export default LandingContentSection
