import React from 'react'

import styles from './LandingParagraph.module.css'

export default function LandingParagraph({ children, style }) {
  return (
    <p className={styles.landingParagraph} style={style}>
      {children}
    </p>
  )
}
