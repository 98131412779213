import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import Heading from 'components/Heading/Heading'
import Wrapper from 'components/Wrapper/Wrapper'

import CustomerReview from './CustomerReview'

import styles from './CustomerReviews.module.css'

function CustomerReviews({ title, reviews }) {
  const randomReviews = reviews.slice(0, 3)

  return (
    <Wrapper>
      <Heading level="h3">
        <h2>{title}</h2>
      </Heading>
      <section className={styles.wrapper}>
        {randomReviews.map((review) => (
          <CustomerReview review={review} key={`random-review_${uuidv4()}`} />
        ))}
      </section>
    </Wrapper>
  )
}

export default CustomerReviews
