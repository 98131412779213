import { getItem, setItem } from './localStorage'

const localStorageKey = 'recentlyViewed'

function readValueFromLocalStorage() {
  let recents
  try {
    recents = JSON.parse(getItem(localStorageKey))
  } catch (error) {
    return []
  }

  if (!Array.isArray(recents)) {
    return []
  }
  return recents
}

export function addToRecentlyViewed(slug: string): void {
  const recents = readValueFromLocalStorage()

  // Add the profile on top of the list
  recents.unshift(slug)

  // Remove duplicates (if you have visited the same profile multiple times)
  const recentsNoDuplicates = recents.filter(
    (_slug, n) => recents.indexOf(_slug) === n,
  )

  // Only show 10 latest
  const recentsLimited = recentsNoDuplicates.slice(0, 10)

  setItem(localStorageKey, JSON.stringify(recentsLimited))
}

export function getRecentlyViewed(exlude: string | string[] = []): string[] {
  const recents = readValueFromLocalStorage()

  return recents.filter((slug) => !exlude.includes(slug))
}
