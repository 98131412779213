import React from 'react'
import cn from 'classnames'

import ConditionalWrapper from 'components/ConditionalWrapper/ConditionalWrapper'
import Heading from 'components/Heading/Heading'
import VideoRequestCard from 'components/VideoRequestCard/VideoRequestCard'
import VideoRequestLoader from 'components/VideoRequestLoader/VideoRequestLoader'
import Wrapper from 'components/Wrapper/Wrapper'

import styles from './VideoRequestCardList.module.css'

export default function VideoRequestCardList({
  title,
  videoRequestIds = [],
  videoRequests = [],
  showTextContent,
  wrapItem = false,
}) {
  if (videoRequestIds.length === 0 && videoRequests.length === 0) {
    return null
  }

  return (
    <ConditionalWrapper
      wrapper={(children) => <Wrapper edgeToEdge>{children}</Wrapper>}
      condition={wrapItem}
    >
      <section className={styles.wrapper}>
        <header className={styles.header}>
          <Heading level="h3">
            <h2>{title}</h2>
          </Heading>
        </header>
        <div className={styles.scrollWrapper}>
          {videoRequests.map((request) => (
            <div key={request.id} className={cn(styles.cardWrapper)}>
              <VideoRequestCard
                request={request}
                showTextContent={showTextContent}
              />
            </div>
          ))}
          {videoRequestIds.map((id) => (
            <div key={id} className={styles.cardWrapper}>
              <VideoRequestLoader id={id} />
            </div>
          ))}
        </div>
      </section>
    </ConditionalWrapper>
  )
}
