import React from 'react'
import { white } from 'lib/theme'

import Heading from 'components/Heading/Heading'
import ImgixImage from 'components/ImgixImage/ImgixImage'
import Link from 'components/Link/Link'

import styles from './ContentCardSmall.module.css'

interface ContentCardSmallCardProps {
  title: string
  linkPath: string
  backgroundImage: { url: string }
}

interface ContentCardSmallProps {
  card: ContentCardSmallCardProps
}

const ContentCardSmall: React.FC<ContentCardSmallProps> = ({ card }) => {
  const { title, linkPath, backgroundImage: backgroundImageObject } = card

  return (
    <Link href={linkPath} passHref>
      <a className={styles.card}>
        <ImgixImage
          src={backgroundImageObject.url}
          alt={title}
          width={160}
          height={97}
        />

        <div className={styles.contentOverlay} />
        <div className={styles.content}>
          {title && (
            <Heading level="h5" color={white}>
              <h4 className={styles.title}>{title}</h4>
            </Heading>
          )}
        </div>
      </a>
    </Link>
  )
}

export default ContentCardSmall
