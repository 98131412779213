import React from 'react'

import ConditionalWrapper from 'components/ConditionalWrapper/ConditionalWrapper'
import { ProfileCollectionTagFromSlug } from 'components/ProfileCollectionTag/ProfileCollectionTag'
import Wrapper from 'components/Wrapper/Wrapper'

import styles from './ProfileCollectionTagList.module.css'

interface ProfileCollectionTagListProps {
  profileCollectionSlugs: string[]
  wrapItem?: boolean
}

const ProfileCollectionTagList: React.FC<ProfileCollectionTagListProps> = ({
  profileCollectionSlugs,
  wrapItem = false,
}) => {
  if (!profileCollectionSlugs || profileCollectionSlugs.length === 0) {
    return null
  }

  return (
    <ConditionalWrapper
      wrapper={(children: React.ReactNode) => (
        <Wrapper edgeToEdge>{children}</Wrapper>
      )}
      condition={wrapItem}
    >
      <nav className={styles.wrapper}>
        {profileCollectionSlugs.map((slug) => (
          <ProfileCollectionTagFromSlug key={slug} slug={slug} />
        ))}
      </nav>
    </ConditionalWrapper>
  )
}

export default ProfileCollectionTagList
