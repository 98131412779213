import React from 'react'
import cn from 'classnames'

import { StepCardSwiperProps } from '../../ProfilePage/type'
import StepCard from './StepCard'

import styles from './StepCardSwiper.module.css'

const StepCardSwiper: React.FC<StepCardSwiperProps> = ({
  cards,
  isHomePage,
}) => {
  if (!cards?.length) return null

  return (
    <div
      className={cn(styles.stepCardSwiperContainer, {
        [styles.centerStepCards]: isHomePage,
      })}
      data-cy="stepCardSwiper"
    >
      {cards.map((card) => (
        <div
          key={card.index}
          className={cn(styles.stepCardSwiperItem, {
            [styles.homePageStepCard]: isHomePage,
          })}
        >
          <StepCard card={card} isHomePage={isHomePage} />
        </div>
      ))}
    </div>
  )
}

export default StepCardSwiper
