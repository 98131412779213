import { gql, useQuery } from '@apollo/client'
import { currentMarketIdVar } from 'graphql/reactive'

export const GET_PUBLIC_PROFILE_COLLECTION_TAG = gql`
  query GetProfileCollectionTag($marketId: ID!, $slug: String!) {
    market(id: $marketId) {
      id
      profileCollection(slug: $slug) {
        id
        slug
        publicCmsItem {
          id
          content
          ... on PublicCmsItemProfileCollectionContent {
            linkPath
          }
        }
      }
    }
  }
`

export default function usePublicProfileCollectionTag(slug, vars = {}) {
  const marketId = currentMarketIdVar()

  const {
    loading,
    data = {},
    error,
  } = useQuery(GET_PUBLIC_PROFILE_COLLECTION_TAG, {
    variables: { slug, marketId, ...vars },
  })

  const profileCollection = data.market && data.market.profileCollection

  return { profileCollection, loading, error }
}
