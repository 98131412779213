import { gql, QueryHookOptions, QueryResult, useQuery } from '@apollo/client'
import type { PageQuery, PageQueryVariables } from 'apollo-gql'
import fullPageFragment from 'graphql/fragments/fullPageFragment'
import { currentLanguageIdVar, currentMarketIdVar } from 'graphql/reactive'

export const GET_PAGE = gql`
  query Page(
    $marketId: ID!
    $languageId: String!
    $slug: String!
    $profilesPerCollection: Int! = 7
  ) {
    market(id: $marketId) {
      id
      page(languageId: $languageId, slug: $slug) {
        ...fullPageFragment
      }
    }
  }

  ${fullPageFragment}
`

type UsePage = (
  slug: PageQueryVariables['slug'],
  useQueryProps?: QueryHookOptions<PageQuery, PageQueryVariables>,
) => {
  page?: PageQuery['market']['page']
} & Pick<QueryResult<PageQuery, PageQueryVariables>, 'loading' | 'error'>

const usePage: UsePage = (slug, useQueryProps = {}) => {
  const marketId = currentMarketIdVar()
  const languageId = currentLanguageIdVar()

  const { loading, data, error } = useQuery<PageQuery>(GET_PAGE, {
    variables: {
      slug,
      marketId,
      languageId,
    },
    returnPartialData: true,
    ...useQueryProps,
  })
  const page = data && data.market && data.market.page

  return { page, loading, error }
}

export default usePage
