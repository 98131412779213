import React from 'react'

import Video from 'components/Video/Video'

import styles from './PhoneVideo.module.css'

export default function PhoneVideo({ videoSrc }) {
  return (
    <div className={styles.videoSection}>
      <div className={styles.videoWrapper}>
        <Video
          id="videoBg"
          autoPlay
          muted
          loop
          playsInline
          key={videoSrc}
          className={styles.video}
          src={videoSrc}
        />
      </div>
    </div>
  )
}
